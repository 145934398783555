import { headers, removeNullInObject, request } from "./lib/common"


export const listOauth = () => {
  return request.get(`/multifamily/admin`, {...headers({})});
}

export const updateOauthGrade = ({email, grade}:GradeProps) => {
  let data = removeNullInObject({email, grade});
  data = {...data, email, grade};
  return request.put(`/multifamily/admin`, 
    data,
    headers({}),
  );
}

export type GradeProps = {
  email: string,
  grade: string,
}