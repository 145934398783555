import React, { Fragment } from 'react';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';

/* styled */
const TableWrap = styled(Table)`
  margin: auto;
  width: 100%;
  color: #333333;
  background-color: #ffffff;
  border-spacing: unset;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%),
  0 2px 4px -2px rgb(0 0 0 / 30%);
  border-radius: 0.35rem;

thead {
  display: table-header-group;
  border-color: inherit;
  font-weight: bold;
  font-size: 0.875rem;
}
tbody {
  display: table-row-group;
  border-color: inherit;
  font-size: 0.875rem;
  color: #333333;

  tr:hover {
    background-color: rgba(255, 255, 255, 0.04);
  }
  tr:first-child td {
    border-top: unset;
  }
  
  tr .report-detail {
    border-top: 0;
    text-align: center;
  }
  tr .report-detail div {
    display: inline-block;
    text-align: left;
  }
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  text-align: left;
}
td {
  padding: 1.125rem 0.875rem;
  border-top: 1px solid #dddddd;
  font-weight: 400;
  word-break: break-all;

  &.no-list-data {
    text-align: center;
  }
  
  &.action-button {
    span {
      cursor: pointer;
      border-radius: 1rem;

      :hover {
        color: #4c9ffe;
      }
    }
  }
  &.action-button.list {
    div {
      padding: 0.375rem 0.6875rem;

      :hover {
        color: #e6e5e8;
      }
    }
  }

  .no-list-data {
    text-align: center;
  }
  .action-button span {
    cursor: pointer;
    border-radius: 1rem;

    :hover {
      color: #4c9ffe;
    }
  }
  .action-button.list span {
    padding: 6px 11px;

    :hover {
      color: #e6e5e8;
    }
  }
}
`;
const TableTH = styled.th`
  padding: 1.125rem .5rem;
  background: #3a4254;
  border-bottom: 1px solid #3a4254;
  top: 60px;
  word-break: keep-all;
  cursor: default;
  color: #ffffff;
  font-weight: 600;

  :first-child {
    border-top-left-radius: .35rem;
    padding: 1.125rem;
  }
  :last-child {
    border-top-right-radius: .35rem;
    padding: .75rem 2rem;
  }
  span svg {
    color: #adb0bb;

    :hover svg {
      display: initial;
    }
  }
  &.grade {
    padding: 1.125rem 0.25rem;
    text-align: center;
  }
`;
type OauthListProps = {
  oauthListItems: any[],
  authError: any,
  handleUpdateGrade: (email:string, oldGrade:string, value:string) => void,
}

const OauthList = ({
  oauthListItems,
  authError,
  handleUpdateGrade,
}:OauthListProps) => {

  return (
    <Fragment>
      <TableWrap>
        <thead>
          <tr>
            <TableTH>이름</TableTH>
            <TableTH>이메일</TableTH>
            <TableTH className='grade'>master</TableTH>
            <TableTH className='grade'>guest</TableTH>
          </tr>
        </thead>
        <tbody>
          {oauthListItems?
            oauthListItems.length > 0 ?
              oauthListItems.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td style={{textAlign: 'center'}}>
                    <input type='radio' name={`${item.email}-grade`} value='master' checked={item.grade === 'master'}
                      onChange={() => handleUpdateGrade(item.email, item.grade, 'master')}/>
                  </td>
                  <td style={{textAlign: 'center'}}>
                    <input type='radio' name={`${item.email}-grade`} value='guest' checked={item.grade === 'guest'}
                      onChange={() => handleUpdateGrade(item.email, item.grade, 'guest')}/> 
                  </td>
                </tr>
              ))
              : <tr>
                  <td colSpan={4} className='no-list-data'>
                    No data
                  </td>
                </tr>
          :<tr>
            <td colSpan={4} className='no-list-data'>
              No data
            </td>
          </tr>
        }
        </tbody>
      </TableWrap>
    </Fragment>
  );
};

export default OauthList;