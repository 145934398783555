import React from "react";
import { QRCode } from "react-qrcode-logo";
import styled from "styled-components";

const QRWrap = styled.ul`
  display: flex;
  margin: auto;
  padding: 0;
  text-align: center;
  flex-direction: column;
  list-style: none;

  li {
    margin: auto;
    width: 50%;
    min-width: 255px;
    padding: 15px;
    border: 1px solid #cccccc;

    .title {
      margin-top: auto;
      margin-bottom: 15px;
      color: #777777;
    }
    .info {
      margin-bottom: auto;
      color: #777777;
      word-break: keep-all
    }
    div {
      font-size: 1.125rem;
      font-weight: bold;
    }

  }
`;

type QrCodeType = {
  data: any,
  header_token: string,
  time: number,
}

const QrCodeViewTest = ({
  data,
  header_token,
  time,
}:QrCodeType) => {
  
  return (
    <QRWrap>
      <li>
        <p className="title">호텔이름</p>
        <div>{data?.hotel?.name}</div>
      </li>
      <li>
        <p className="title">객실</p>
        <div>{data?.room?.name}</div>
      </li>
      <li>
        <p className="title">PinCode</p>
        <div>{data?.pincodes[0].pincode}</div>
      </li>
      <li>
        <p className="title">
          엘리베이터
          <span> 또는 </span>
          공용도어 QR코드
        </p>
        <div>
          <QRCode
            value={`${data?.hotel?.id}@${data?.id}`}
            size={200}
            ecLevel='L'
            /* logoImage={require('../../assets/images/H2O.png')} 
            logoWidth={30} 
            logoHeight={30}   */
          />
        </div>
        <p className="info">
          객실로 이동하는 엘리베이터 버튼 또는 공용도어를 활성화 하기 위해 QR스캐너에 지금 화면을 읽어 주세요.
        </p>
        
      </li>
      <li>
        <p className="title">로그인 토큰값</p>
        <QRCode
          value={header_token}
          size={200} 
          ecLevel='L'
        />
        <p className="info">
          로그인 토큰 입력 후 상단의 공용도어 QR을 입력해야 합니다.
        </p>
        <p className="info">
          인증 시간: {Math.floor(time / 60) > 9? Math.floor(time / 60) : `0${Math.floor(time / 60)}`}:{time % 60 > 9? time % 60 : `0${time % 60}`}
        </p>
      </li>
    </QRWrap>
  )
};

export default QrCodeViewTest;