import React, { Fragment } from 'react';
import _ from 'lodash';
import Filter from '../Commons/Filter';
import Table from '../Commons/Table';
import { doorlockStatusFormatter, doorlockBatteryFormatter, dateFormatter } from '../../lib/formatter';
import doorlockFormatter from './DoorlockFormatter';
import { doorlockFormatterFwTypeProps } from '../../types/formatter';
import styled from 'styled-components';
import { DoorlockAllListProps } from '../../types/doorlock';

/* styled */
const ContentTitle = styled.div`
  position: absolute;
  top: -3.1875rem;
  right: 0;
  height: 2rem;

  @media screen and (max-width: 1650px) {
    position: relative;
    top: -0.625rem;
    height: auto;
    margin-left: -1.875rem;
    margin-bottom: 0.625rem;
    padding: 0.625rem 1.875rem;
    width: 100%;
    background: #ffffff;
    border-top: 1px solid #cccccc;
  }
  @media screen and (max-width: 768px) {
    margin-left: -1rem;
    padding: 0.625rem 1rem;
    top: 0;
  }
`;
const BlankWrap = styled.div`
  width: 100%;
  height: 2.25rem;
`;
const ContentTopButtonForm = styled.div`
  float: right;
  margin: 1.125rem auto;

  .center {
    display: block;
    padding-top: 0;

    .detail {
      right: 0;
    }
    .pl20 {
      padding-left: 1.25rem;
    }
  }
`;
const ContentButton = styled.button`
  height: 2.3rem;
  min-width: 5rem;
  padding: 0.5rem;
  border-radius: .35rem;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: #044dac;
  border: 0px;
  cursor: pointer;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 
  0 2px 4px -2px rgb(0 0 0 / 30%);

  :hover {
    background-color: #4c9ffe;
  }
  :disabled {
    background-color: #949494;
    cursor: default;
  }
`;
const DoorlockAllList = ({
  doorlockListItems,
  selectedHotelListItems,
  buildingListItems,
  floorListItems,
  filterItem,
  sortItem,
  userRole,
  doorlockName,
  serialName,
  fwVersionName,
  doorlockTotal,
  handleFilter,
  handleFilterHotel,
  handleinitFilter,
  handleDoorlockDetail,
  handleDoorlockUninstallModal,
  changeSort,
  handleRefacth,
}:DoorlockAllListProps) => {
  const statusItems = [
    { key: '미설치', value: 'created' },
    { key: '설치됨', value: 'installed' },
  ];

  return (
    <Fragment>
      <ContentTitle>
        <Filter
          columns={[
            {
              key: 'hotelId',
              type: 'hotel-select',
              size: 'xlg',
              placeholder: '단지',
              value: filterItem.selectHotelId,
              action: handleFilterHotel,
              selectItems: selectedHotelListItems? selectedHotelListItems : '',
              selectItemKey: 'label',
              selectItemValue: 'value',
              isRemoveButton: true,
            },
            {
              key: 'buildingId',
              type: 'select',
              size: 'xlg',
              placeholder: '빌딩',
              value: filterItem.buildingId,
              action: handleFilter,
              selectItems: buildingListItems? buildingListItems : '',
              selectItemKey: 'name',
              selectItemValue: 'id',
              isRemoveButton: true,
            },
            {
              key: 'floorId',
              type: 'select',
              size: 'xlg',
              placeholder: '층',
              value: filterItem.floorId,
              action: handleFilter,
              selectItems: floorListItems? floorListItems : '',
              selectItemKey: 'name',
              selectItemValue: 'id',
              isRemoveButton: true,
            },
            {
              key: 'name',
              size: 'xlg',
              placeholder: '도어락이름',
              value: doorlockName,
              action: handleFilter,
            },
            {
              key: 'serial',
              size: 'xlg',
              placeholder: '시리얼번호',
              value: serialName,
              action: handleFilter,
            }, 
            {
              key: 'type',
              type: 'select',
              size: 'xlg',
              placeholder: '타입',
              value: filterItem.type,
              action: handleFilter,
              selectItems: doorlockFormatter.typeItems,
              selectItemKey: 'key',
              selectItemValue: 'value',
              isRemoveButton: true,
            },
              {
              key: 'status',
              type: 'select',
              size: 'xlg',
              placeholder: '상태',
              value: filterItem.status,
              action: handleFilter,
              selectItems: statusItems,
              selectItemKey: 'key',
              selectItemValue: 'value',
              isRemoveButton: true,
            }, 
            {
              key: 'fwType',
              type: 'select',
              size: 'xlg',
              placeholder: '모델명',
              value: filterItem.fwType,
              action: handleFilter,
              selectItems: doorlockFormatter.fwTypeItems,
              selectItemKey: 'key',
              selectItemValue: 'value',
              isRemoveButton: true,
            },
            {
              key: 'fwVersion',
              size: 'xlg',
              placeholder: 'FW버전',
              value: fwVersionName,
              action: handleFilter,
            },
          ]}
          init={handleinitFilter}
        />
      </ContentTitle>
      <BlankWrap>
      <ContentTopButtonForm hidden={true}>
        <ContentButton onClick={() => handleRefacth()}>
          <span>리패치</span>
        </ContentButton>
      </ContentTopButtonForm>

      </BlankWrap>
      <Table
        columns={[
          {
            key: 'view',
            text: '상세보기',
            width: '5%',
            moveToPage: {
              func: handleDoorlockDetail,
              params: ['id', 'hotel_id'],
            },
          },
          {
            key: 'name',
            text: '도어락이름',
            width: '11%',
            sort: {
              func: changeSort,
              params: 'name',
              selected: sortItem.sort === 'name',
              order: sortItem.sort === 'name' ? sortItem.order : 'desc',
            }
          },
          {
            key: 'type',
            text: '타입',
            width: '8%',
            formatter: {
              func: doorlockFormatter.type,
              params: ['type'],
            },
          },
          {
            key: 'dl_serial',
            text: '시리얼번호',
            width: '8%',
          },
          {
            key: 'hotel_name',
            text: '단지',
            width: '8%',
            sort: {
              func: changeSort,
              params: 'hotel_name',
              selected: sortItem.sort === 'hotel_name',
              order: sortItem.sort === 'hotel_name' ? sortItem.order : 'desc',
            },
          },
          {
            key: 'building_name',
            text: '빌딩',
            width: '8%',
            sort: {
              func: changeSort,
              params: 'building_name',
              selected: sortItem.sort === 'building_name',
              order: sortItem.sort === 'building_name' ? sortItem.order : 'desc',
            },
          },
          {
            key: 'floor_name',
            text: '층',
            width: '7%',
            sort: {
              func: changeSort,
              params: 'floor_name',
              selected: sortItem.sort === 'floor_name',
              order: sortItem.sort === 'floor_name' ? sortItem.order : 'desc',
            },
          },
          {
            key: 'status',
            text: '상태',
            width: '5%',
            formatter: {
              func: doorlockStatusFormatter,
              params: ['status'],
            },
            sort: {
              func: changeSort,
              params: 'status',
              selected: sortItem.sort === 'status',
              order: sortItem.sort === 'status' ? sortItem.order : 'desc',
            },
          },
          {
            key: 'fw_type',
            text: '모델명',
            width: '6%',
            formatter: {
              func: (fwType:doorlockFormatterFwTypeProps) => fwType ? doorlockFormatter.fwType(fwType) : '-',
              params: ['fw_type'],
            },
          },
          {
            key: 'fw_version',
            text: 'FW버전',
            width: '5%',
          },
          {
            key: 'fw_battery',
            text: '배터리상태',
            width: '5%',
            formatter: {
              func: (fwBattery:number) => fwBattery || _.isNumber(fwBattery) ? doorlockBatteryFormatter(fwBattery) : '-',
              params: ['fw_battery'],
            },
            sort: {
              func: changeSort,
              params: 'fw_battery',
              selected: sortItem.sort === 'fw_battery',
              order: sortItem.sort === 'fw_battery' ? sortItem.order : 'desc',
            },
          },
          {
            key: 'created_at',
            text: '생성일자',
            width: '10%',
            formatter: {
              func: dateFormatter,
              params: ['created_at'],
            },
            sort: {
              func: changeSort,
              params: 'created_at',
              selected: sortItem.sort === 'created_at',
              order: sortItem.sort === 'created_at' ? sortItem.order : 'desc',
            },
          },
          {
            key: 'updated_at',
            text: '수정일자',
            width: '10%',
            formatter: {
              func: dateFormatter,
              params: ['updated_at'],
            },
            sort: {
              func: changeSort,
              params: 'updated_at',
              selected: sortItem.sort === 'updated_at',
              order: sortItem.sort === 'updated_at' ? sortItem.order : 'desc',
            },
          },
          {
            key: 'button',
            text: '',
            width: '2%',
            items: [
              {
                text: '설치 삭제',
                disabled: {
                  func: (status:string) => status !== 'installed',
                  params: ['status'],
                },
                action: {
                  func: (doorlockId:string, status: string, doorlockName:string, hotelId: string) => handleDoorlockUninstallModal(doorlockId, status, doorlockName, hotelId),
                  params: ['id', 'status', 'name', 'hotel_id'],
                },
              },
            ],
          },
        ]}
        datas={doorlockListItems}
        userRole={userRole}
        total={doorlockTotal}
      />
    </Fragment>
  );
};

export default DoorlockAllList;