import React, { useState, useEffect, Fragment, useCallback, ChangeEvent } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ResponseFailModal, ResponseSuccessModal } from '../../components/Modal/Response';
import StaffCreate from '../../components/Staff/StaffCreate';
import { NumberCheck } from '../../lib/useNumberCheck';
import { RootState } from '../../stores';
import { changeField, changeResult, createStaffAction, initialize } from '../../stores/staff';
import { checkProps, StaffCreateContainerProps } from '../../types/staff';

const StaffCreateContainer = ({
  isOpen,
  toggle,
  reload,
}:StaffCreateContainerProps) => {
  const dispatch = useDispatch();    
  const { staffItem, staffSuccess, staffError, hotelRole  } = useSelector(({ staff, header }:RootState) => ({
    staffItem: staff.staff,
    staffSuccess: staff.staffCreateSuccess,
    staffError: staff.staffCreateError,
    hotelRole: header.hotelRole,
  }));
  const [ phoneNumbers, setPhoneNumbers ] = useState<string>('');
  const [ isOpenStaffCreateSuccessModal, setIsOpenStaffCreateSuccessModal ] = useState<boolean>(false);
  const [ isOpenStaffCreateFailModal, setIsOpenStaffCreateFailModal ] = useState<boolean>(false);
  const [ isOpenRequiredParameterFailModal, setIsOpenRequiredParameterFailModal ] = useState<boolean>(false);
  const [ isOpenPhoneNumberKoFailModal, setIsOpenPhoneNumberKoFailModal ] = useState<boolean>(false);
  const [ messageStaffCreateFail, setMessageStaffCreateFail ] = useState<string>('');

  //인풋 변경 이벤트 핸들러
  const handleChange = useCallback((e:ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;
    if(name === 'phoneNumber'){
      setPhoneNumbers(value);
    }else{
      dispatch(
        changeField({
            key: name,
            value,
        })
      );
    }
  },[dispatch]);

  //체크용
  const validateCreateStaffItem = ({name, phoneNumber, role}:checkProps) => {
    return name && phoneNumber && role;
  };

  //한국 핸드폰 번호 체크
  const phoneNumberKoCheck = (countryNumber:string, phoneNumber:string) => {
    if(countryNumber === '82'){
      if(phoneNumber.length > 10){
        const number_arr = ['010','011','015','016','017','018','019'];
        const str = phoneNumber.substring(0,3);
        if(number_arr.includes(str)) return true;
      }
    }else{
      return true;
    }
    return false;
  }

  const handleCreateStaff = useCallback(() => {
    if (!validateCreateStaffItem({...staffItem, phoneNumber: phoneNumbers})){
      setIsOpenRequiredParameterFailModal(true); 
      return;
    }
    if(!phoneNumberKoCheck(staffItem.countryNumber, phoneNumbers)){
      setIsOpenPhoneNumberKoFailModal(true); 
      return;
    }
    const reqNum = staffItem.countryNumber + Number.parseInt(phoneNumbers);
    dispatch(createStaffAction({...staffItem, phoneNumber: reqNum}));
  },[dispatch, staffItem, phoneNumbers]);

  useEffect(() => {
    if(staffError){
      if (!staffError.response){
        setMessageStaffCreateFail(staffError.message);
        setIsOpenStaffCreateFailModal(true);
      }else{
        if (staffError.response.data === undefined){
          setMessageStaffCreateFail(staffError.message);
        }else{
          setMessageStaffCreateFail(`${staffError.response.data.code}, ${staffError.response.data.message}`);
          if(staffError.response.data?.code === 401 || staffError.response.data?.code === 419) reload();
        }
        setIsOpenStaffCreateFailModal(true);
      }
      dispatch(changeResult({
        key: 'staffCreateError',
        value: null,
      }));
      return;
    }
    if(staffSuccess){
      toggle();
      setIsOpenStaffCreateSuccessModal(true);
      setTimeout(() => {
        setIsOpenStaffCreateSuccessModal(false);
        reload();
      }, 1500);
      dispatch(initialize());
    }
  },[staffSuccess, staffError, toggle, reload, dispatch]);

  useEffect(() => {
    if(isOpen) dispatch(initialize());
  }, [dispatch, isOpen]);

  useEffect(() => {
    const value = NumberCheck(phoneNumbers);
    setPhoneNumbers(value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[phoneNumbers]);

  return (
    <Fragment>
      <Modal
        show={isOpen}
        onHide={() => toggle()}
        centered
        backdrop='static'
      >
        <ModalBody className='text-center m-3' style={{margin:0, padding:0}}>
          <StaffCreate 
            staffItem={staffItem}
            hotelRole={hotelRole}
            phoneNumbers={phoneNumbers}
            handleChange={handleChange}
            handleCreateStaff={handleCreateStaff}
            toggle={toggle}
          />
        </ModalBody>
      </Modal>
      <ResponseSuccessModal
        isOpen={isOpenStaffCreateSuccessModal}
        toggle={() => setIsOpenStaffCreateSuccessModal(!isOpenStaffCreateSuccessModal)}
        message='스태프 생성이 완료 되었습니다.'
      />
      <ResponseFailModal
        isOpen={isOpenStaffCreateFailModal}
        toggle={() => setIsOpenStaffCreateFailModal(!isOpenStaffCreateFailModal)}
        message={messageStaffCreateFail || '스태프 생성에 실패 하였습니다.'}
      />
      <ResponseFailModal
        isOpen={isOpenRequiredParameterFailModal}
        toggle={() => setIsOpenRequiredParameterFailModal(!isOpenRequiredParameterFailModal)}
        message='필수 입력값을 확인 해주세요.'
      />
      <ResponseFailModal
        isOpen={isOpenPhoneNumberKoFailModal}
        toggle={() => setIsOpenPhoneNumberKoFailModal(!isOpenPhoneNumberKoFailModal)}
        message='올바른 전화번호를 입력해 주세요.'
      />
    </Fragment>
  );
};

export default StaffCreateContainer;