import React, { useState, useEffect, Fragment, useCallback, ChangeEvent } from 'react';
import addDays from 'date-fns/addDays';
import parseISO from 'date-fns/parseISO';
import { Modal, ModalBody } from 'react-bootstrap';
import { ResponseFailModal, ResponseSuccessModal } from '../../components/Modal/Response';
import BookingUpdate from '../../components/Booking/BookingUpdate';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../stores';
import { changeField, changeResult, selectBookingAction, updateBookingAction } from '../../stores/booking';
import { BookingUpdateContainerProps, checkProps } from '../../types/booking';
import { NumberCheck } from '../../lib/useNumberCheck';

const BookingUpdateContainer = ({
  isOpen,
  toggle,
  reload,
  bookingId,
}:BookingUpdateContainerProps) => {  
  const dispatch = useDispatch();
  const { bookingItem, bookingUpdateSuccess, bookingUpdateError } = useSelector(({ booking }:RootState) => ({
      bookingItem: booking.booking,
      bookingUpdateSuccess: booking.bookingUpdateSuccess,
      bookingUpdateError: booking.bookingUpdateError,
  }));
  const [ isOpenBookingUpdateSuccessModal, setIsOpenBookingUpdateSuccessModal ] = useState<boolean>(false);
  const [ isOpenBookingUpdateFailModal, setIsOpenBookingUpdateFailModal ] = useState<boolean>(false);
  const [ isOpenRequiredParameterFailModal, setIsOpenRequiredParameterFailModal ] = useState<boolean>(false);
  const [ isOpenPhoneNumberKoFailModal, setIsOpenPhoneNumberKoFailModal ] = useState<boolean>(false);
  const [ messageBookingUpdateFail, setMessageBookingUpdateFail ] = useState<string>('');
  const [ phoneNumbers, setPhoneNumbers ] = useState<string>('');
  const [ phoneNumberFlag, setPhoneNumberFlag ] = useState<boolean>(false);

  const handleSelectBooking = useCallback(() => {
    dispatch(selectBookingAction({bookingId}));
  },[bookingId, dispatch]);

  //인풋 변경 이벤트 핸들러
  const handleChange = useCallback((form:string, e:ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;
    const value2 = name === 'roomIds'? [e.target.value] : value;
    if(name === 'phoneNumber'){
      setPhoneNumbers(value);
      const rexp = /[^0-9]/g;
      if(rexp.test(value)) setPhoneNumberFlag(true);
    }else{
      dispatch(
        changeField({
            form: form,
            key: name,
            value: value2,
        })
      );
    }
},[dispatch]);

  //변경 이벤트 날짜
  const handleChangeDate = useCallback((date:Date, form:string, name:string) => {
    dispatch(
        changeField({
            form: form,
            key: name,
            value: date,
        })
    );
  },[dispatch]);

  const handleSetMaxDate = () => {
    return addDays(parseISO(bookingItem.checkinAt), 15);
  };

  //체크용
  const validateUpdateBookingItem = ({userName, phoneNumber, checkinAt, checkoutAt}:checkProps) => {
    return userName && phoneNumber && checkinAt && checkoutAt;
  };

  //한국 핸드폰 번호 체크
  const phoneNumberKoCheck = (countryNumber:string, phoneNumber:string) => {
    if(countryNumber === '82'){
      if(phoneNumber.length > 10){
        const number_arr = ['010','011','015','016','017','018','019'];
        const str = phoneNumber.substring(0,3);
        if(number_arr.includes(str)) return true;
      }
    }else{
      return true;
    }
    return false;
  }

  const handleUpdateBooking = useCallback(() => {
    if (!validateUpdateBookingItem({...bookingItem, phoneNumber: phoneNumbers})){
      setIsOpenRequiredParameterFailModal(true); 
      return;
    }
    if(!phoneNumberKoCheck(bookingItem.countryNumber, phoneNumbers)){
      setIsOpenPhoneNumberKoFailModal(true); 
      return;
    }
    const reqNum = bookingItem.countryNumber + Number.parseInt(phoneNumbers);
    dispatch(updateBookingAction({...bookingItem, bookingId, phoneNumber: reqNum}));
  },[bookingId, bookingItem, dispatch, phoneNumbers]);

  const handleToggle = useCallback(() => {
    toggle();
    setPhoneNumbers('');
  },[toggle]);

  useEffect(() => {
    if(bookingUpdateError){
      if (!bookingUpdateError.response){
        setMessageBookingUpdateFail(bookingUpdateError.message);
        setIsOpenBookingUpdateFailModal(true);
      }else{
        if (bookingUpdateError.response.data === undefined){
          setMessageBookingUpdateFail(bookingUpdateError.message);
        }else{
          setMessageBookingUpdateFail(`${bookingUpdateError.response.data.code}, ${bookingUpdateError.response.data.message}`);
          if(bookingUpdateError.response.data?.code === 401 || bookingUpdateError.response.data?.code === 419) reload();
        }
        setIsOpenBookingUpdateFailModal(true);
      }
      
      dispatch(
        changeResult({
          key: 'bookingUpdateError',
          value: null,
        })
      );
      return;
    }
    if(bookingUpdateSuccess){
      handleToggle();
      setIsOpenBookingUpdateSuccessModal(true);
      setTimeout(() => {
        setIsOpenBookingUpdateSuccessModal(false);
        reload();
      }, 1500);
      dispatch(
        changeResult({
          key: 'bookingUpdateSuccess',
          value: null,
        })
      );
    }
  },[bookingUpdateSuccess, bookingUpdateError, handleToggle, reload, dispatch]);

  useEffect(() => {
    if (isOpen && bookingId) handleSelectBooking();
  }, [bookingId, handleSelectBooking, isOpen]);

  useEffect(() => {
    if(phoneNumbers === ''){
      setPhoneNumbers(bookingItem.phoneNumber);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[bookingItem]);

  useEffect(() => {
    if(phoneNumberFlag){
      const value = NumberCheck(phoneNumbers);
      setPhoneNumbers(value);
      setPhoneNumberFlag(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[phoneNumberFlag, phoneNumbers]);

  return (
    <Fragment>
      <Modal
        show={isOpen}
        onHide={() => handleToggle()}
        centered
        backdrop='static'
      >
        <ModalBody className='text-center m-3' style={{margin:0, padding:0}}>
          <BookingUpdate 
              bookingItem={bookingItem}
              phoneNumbers={phoneNumbers}
              handleChange={handleChange}
              handleChangeDate={handleChangeDate}
              handleSetMaxDate={handleSetMaxDate}
              handleUpdateBooking={handleUpdateBooking}
              toggle={handleToggle}
          />
        </ModalBody>
      </Modal>
      <ResponseSuccessModal
        isOpen={isOpenBookingUpdateSuccessModal}
        toggle={() => setIsOpenBookingUpdateSuccessModal(!isOpenBookingUpdateSuccessModal)}
        message='예약 정보 수정이 완료 되었습니다.'
      />
      <ResponseFailModal
        isOpen={isOpenRequiredParameterFailModal}
        toggle={() => setIsOpenRequiredParameterFailModal(!isOpenRequiredParameterFailModal)}
        message='필수 입력값을 확인 해주세요.'
      />
      <ResponseFailModal
        isOpen={isOpenPhoneNumberKoFailModal}
        toggle={() => setIsOpenPhoneNumberKoFailModal(!isOpenPhoneNumberKoFailModal)}
        message='올바른 전화번호를 입력해 주세요.'
      />
      <ResponseFailModal
        isOpen={isOpenBookingUpdateFailModal}
        toggle={() => setIsOpenBookingUpdateFailModal(!isOpenBookingUpdateFailModal)}
        message={messageBookingUpdateFail || '예약 정보 수정에 실패 하였습니다.'}
      />
    </Fragment>
  );
};

export default BookingUpdateContainer;