import { doorlockFormatterFwTypeProps, doorlockFormatterTypeProps } from "../../types/formatter";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  type(type:doorlockFormatterTypeProps) {
    return {
      commonroom: '공용도어',
      room: '객실',
      building: '빌딩도어',
      floor: '층도어',
      elevator: '엘리베이터',
      keytag: '키택',
    }[type] || 'Unknown';
  },
  fwType(fwType:doorlockFormatterFwTypeProps) {
    const model = {
      1: 'IG200',
      2: 'IG200',
      3: 'IG200',
      4: 'IG200',
      60: 'IG404',
      71: 'IG300',
      72: 'IG301',
      73: 'IG302',
      74: 'IG400',
      75: 'IG400',
      76: 'IG400',
      81: 'IG300A/B',
      82: 'IG301',
      83: 'IG302',
      84: 'IG400',
      85: 'IG400',
      86: 'IG402',
      87: 'IG700',
      88: 'IG710',
      89: 'IG403',
      90: 'IG101M',
      91: 'IG303',
      92: 'IG404',
      93: 'IG720',
      94: 'IG304',
      111: 'IG330A/B',
      112: 'IG331',
      113: 'IG332',
      114: 'IG430',
      115: 'IG431',
      116: 'IG432',
      119: 'IG433',
      121: 'IG333',
      122: 'IG434',
      124: 'IG334',
      125: 'IG435',
      128: 'IG100M',
      129: 'IG102M',
      130: 'IG103M',
      131: 'IG103M',
      140: 'IG500',
      150: 'IG600',
      151: 'IG600',
      160: 'IG700',
      161: 'IG710',
      162: 'IG720',
      170: 'IG800',
      171: 'IG800',
      172: 'IG501',
      173: 'IG501',
      174: 'IG704',
      175: 'IG704',
      176: 'IG703',
      177: 'IG703',
      178: 'IG807',
      179: 'IG807',
      180: 'IG808',
      181: 'IG808',
      182: 'IG830',
      190: 'IG500MF',
      191: 'IG600MF',
      192: 'IG600MF',
      193: 'IG700MF',
      194: 'IG710MF',
      195: 'IG720MF',
      196: 'IG800MF',
      197: 'IG800MF',
      198: 'IG501MF',
      199: 'IG501MF',
      200: 'IG704MF',
      201: 'IG704MF',
      202: 'IG703MF',
      203: 'IG703MF',
      204: 'IG807MF',
      205: 'IG807MF',
      206: 'IG808MF',
      207: 'IG808MF',
      208: 'IG830MF',
    }[fwType] || 'Unknown';

    return `${model}(${fwType})`;
  },
  typeItems: [
    { key: '객실', value: 'room' },
    { key: '공용도어', value: 'commonroom' },
    { key: '빌딩도어', value: 'building' },
    { key: '층도어', value: 'floor' },
    { key: '엘리베이터', value: 'elevator' },
    { key: '키택', value: 'keytag' },
  ],
  fwTypeItems: [
    { key: 'IG100M', value: '128' },
    { key: 'IG101M', value: '90' },
    { key: 'IG102M', value: '129' },
    { key: 'IG103M', value: '130,131' },
    { key: 'IG200', value: '1,2,3,4' },
    { key: 'IG300', value: '71' },
    { key: 'IG300A/B', value: '81' },
    { key: 'IG301', value: '72,82' },
    { key: 'IG302', value: '73' },
    { key: 'IG302', value: '83' },
    { key: 'IG303', value: '91' },
    { key: 'IG304', value: '94' },
    { key: 'IG330A/B', value: '111' },
    { key: 'IG331', value: '112' },
    { key: 'IG332', value: '113' },
    { key: 'IG333', value: '121' },
    { key: 'IG334', value: '124' },
    { key: 'IG400', value: '74,75,76,84,85' },
    { key: 'IG402', value: '86' },
    { key: 'IG403', value: '89' },
    { key: 'IG404', value: '60,92' },
    { key: 'IG430', value: '114' },
    { key: 'IG431', value: '115' },
    { key: 'IG432', value: '116' },
    { key: 'IG433', value: '119' },
    { key: 'IG434', value: '122' },
    { key: 'IG435', value: '125' },
    { key: 'IG500', value: '140' },
    { key: 'IG500MF', value: '190' },
    { key: 'IG501MF', value: '198,199' },
    { key: 'IG600', value: '150,151' },
    { key: 'IG600MF', value: '191,192' },
    { key: 'IG700', value: '87,160' },
    { key: 'IG700MF', value: '193' },
    { key: 'IG703MF', value: '202,203' },
    { key: 'IG704MF', value: '200,201' },
    { key: 'IG710', value: '88,161' },
    { key: 'IG710MF', value: '194' },
    { key: 'IG720', value: '93,162' },
    { key: 'IG720MF', value: '195' },
    { key: 'IG800', value: '170,171' },
    { key: 'IG800MF', value: '196,197' },
    { key: 'IG807MF', value: '204,205' },
    { key: 'IG808', value: '180,181' },
    { key: 'IG808MF', value: '206,207' },
    { key: 'IG830', value: '182' },
    { key: 'IG830MF', value: '208' },
  ],
};
