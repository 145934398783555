import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import * as xlsx from 'xlsx';
import useDebounce from '../../lib/useDebounce';
import ReportList from '../../components/Report/ReportList';
import { ResponseFailModal } from '../../components/Modal/Response';
import { RootState } from '../../stores';
import { changeField, setFilterItemAction } from '../../stores/reportList';
import ReportDetail from '../../components/Report/ReportDetail';
import { reportDataExcelColumns, reportListFormatter } from '../../lib/formatter';
import styled from 'styled-components';
import { ReportListContainerProps } from '../../types/report';
import { listReport, listReportApi } from '../../api/report';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';

/* styled */
const FormCard = styled.article`
    padding-bottom: 2rem;
    width: 100%;
    border-radius: 0.3rem;
    position: relative;
`;

const ReportListContainer = ({
  isOpen,
  reportView,
  handleViewChange,
}:ReportListContainerProps) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { filterItem, userRole } = useSelector(({ reportList, header }:RootState) => ({
      filterItem: reportList.filterItem,
      userRole: header.userRole,
  }));
  const [ isOpenApiErrorModal, setIsOpenApiErrorModal ] = useState<boolean>(false);
  const [ apiErrorMessage, setApiErrorMessage ] = useState<string>('');
  const [ apiErrorSubMessage, setApiErrorSubMessage ] = useState<string>('');
  const [ isOpenReportDetailModal, setIsOpenReportDetailModal ] = useState<boolean>(false);
  const [ selectedReportItem, setSelectedReportItem ] = useState<any>({});
  const [ buildingName, setBuildingName ] = useState<string>(filterItem.buildingName);

  const queryClient = useQueryClient();
  //react-Query 시작
  const {data, status, error:reportError} = useQuery<any, any>(['report_list', filterItem], () => listReport({...filterItem})
  ,{
    staleTime: 1 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });
  
  //필터 핸들링
  const handleFilter =  useCallback((targetItem:any, isDebounce:boolean) => {
    if(Object.keys(targetItem)[0] === 'buildingName') setBuildingName(targetItem.buildingName);

    !isDebounce? dispatch(setFilterItemAction({...filterItem, ...targetItem})) : 
      handleDebounce(() => dispatch(setFilterItemAction({...filterItem, ...targetItem})));
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filterItem]);

  //필터 초기화 버튼
  const handleinitFilter = useCallback(() => {
    const targetItem:any = {
        buildingName: '',
      }
    dispatch(setFilterItemAction({...filterItem, ...targetItem}));
    setBuildingName('');
    queryClient.refetchQueries(['report_list', filterItem]);
  },[dispatch, filterItem, queryClient]);

  const handleExportDataToExcel = () => {
    if(data){
      if(data.data.items){
        try{
          let excelDataColumns = reportDataExcelColumns(filterItem.reportType);
          const excelDatas = data.data.items.map((report:any) => {
            const formattedData:any = {};
            excelDataColumns.map((column) => {
              _.set(formattedData, column.name, reportListFormatter(report, column.key, userRole));
              if (!_.isString(formattedData[column.name])) _.set(formattedData, column.name, JSON.stringify(formattedData[column.name]));
              return formattedData;
            });
            return formattedData;
          });
          const worksheet = xlsx.utils.json_to_sheet(excelDatas);
          const workbook = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(workbook, worksheet, 'sheet1');
          xlsx.writeFile(workbook, `${filterItem.reportType}_report.xlsx`);
        } catch (error) {
          setApiErrorMessage('리포트 엑셀 다운로드 실패');
          setApiErrorSubMessage('');
          setIsOpenApiErrorModal(true);
        }
      }
    }
  };

  const handleSelectReport = (reportItem:listReportApi) => {
    setSelectedReportItem(reportItem);
    setIsOpenReportDetailModal(true);
  };

  //디바운싱 훅으로 이동
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebounce = useCallback(
    useDebounce((handleListReport) => handleListReport(), 300) //0.3초 동안 미입력 시 함수 실행
  ,[]);

  useEffect(() => {
    if(isOpen) queryClient.invalidateQueries(['report_list', filterItem]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isOpen]);
      
  useEffect(() => {
    if(reportError){
      if(reportError?.response){
        if(reportError.response.data?.code === 401 || reportError.response.data?.code === 419){
          localStorage.clear();
          navigation('/login');
          dispatch(changeField({
            key: 'listLoading',
            value: false,
          }));
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[reportError]);
  
  useEffect(() => {
    if(status === 'loading'){
      dispatch(changeField({
        key: 'listLoading',
        value: true,
      }));
    }else{
      dispatch(changeField({
        key: 'listLoading',
        value: false,
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[status]);

  return (
    <Fragment>
      <FormCard>
        <ReportList
          reportListItems={data?.data.items||[]}
          filterItem={filterItem}
          userRole={userRole}
          buildingName={buildingName}
          handleFilter={handleFilter}
          handleinitFilter={handleinitFilter}
          handleExportDataToExcel={handleExportDataToExcel}
          handleSelectReport={handleSelectReport}
        />
      </FormCard>
      <ReportDetail
        isOpen={isOpenReportDetailModal}
        toggle={() => setIsOpenReportDetailModal(!isOpenReportDetailModal)}
        reportItem={selectedReportItem}
        reportType={filterItem.reportType}
        userRole={userRole}
      />
      <ResponseFailModal
        isOpen={isOpenApiErrorModal}
        toggle={() => setIsOpenApiErrorModal(!isOpenApiErrorModal)}
        message={apiErrorMessage}
        subMessage={apiErrorSubMessage}
      />
    </Fragment>
  );
}

export default ReportListContainer;