import React, { Fragment,  useCallback, useState } from 'react';
import {  useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { listOauth, updateOauthGrade } from '../../api/oauth';
import MainLayout from '../../components/Layout/MainLayout';
import OauthList from '../../components/Oauth/OauthList';
import { RootState } from '../../stores';

/* styled */
const FormCard = styled.article`
    width: 100%;
    position: relative;
`;
const ContentTitle2 = styled.div`
  display: flex;
  padding: 0.625rem 1.875rem;
  margin-left: -1.875rem;
  margin-bottom: 1rem;
  width: 100%;
  height: 2rem;
  font-weight: bold;
  position: relative;
  background: #ffffff;

    h1,
    h2 {
      cursor: default;
      margin: 0;
      line-height: 2rem;
    }
    
@media screen and (max-width: 768px) {
  margin-left: -1rem;
  padding: 0.625rem 1rem;
}
`;
const PopupWrap = styled.div`
  display: none;
  position: fixed;
  top: 10px;
  left: calc(50% - 150px);
  padding: 0.25rem;
  width: 300px;
  font-weight: bold;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.7);

  div {
    padding: 0.5rem 1rem;
    text-align: center;
    border: 1px solid #ffffff;
  }

  &.fade-in {
    display: block;
    opacity: 1;
    animation: fadein 0.5s;
  }
  @keyframes fadein {
      from {
          opacity: 0;
      }
      to {
          opacity: 1;
      }
  }
`;

const OauthListContainer = () => {
  const { homeMenuItem } = useSelector(({ breadcrumb }:RootState) => ({
      homeMenuItem: breadcrumb.homeMenuItem,
  }));

  const [ Message, setMessage] = useState<string>('');
  const [ isOpenMessage, setIsOpenMessage] = useState<boolean>(false);

  const queryClient = useQueryClient();  
  //react-Query 시작
  const {data, error:authError} = useQuery<any, any>(['oauth_list'], () => listOauth()
  ,{
    refetchOnWindowFocus: false,
  });
  
  //MainLayout - refresh용
  const changeOauth = useCallback(() => {
  }, []);

  const handleUpdateGrade = async(email:string, oldGrade:string, value:string) => {
    if(oldGrade === value) return;
    try {
      await updateOauthGrade({email, grade: value})
      setMessage('수정 완료했습니다.');
      setIsOpenMessage(true);
      setTimeout(() => {
        setIsOpenMessage(false);
      }, 1000);
      queryClient.invalidateQueries('oauth_list');
    } catch (error:any) {
      if (!error.response) setMessage(error.message);
      else setMessage(`${error.response.data?.code}, ${error.response.data?.message}`);
      setIsOpenMessage(true);
      setTimeout(() => {
        setIsOpenMessage(false);
      }, 1000);
    }
  }

  return (
    <Fragment>
      <MainLayout
        refresh={changeOauth}
        ContentBody={(
          <>
          <div hidden={homeMenuItem !== 'oauth'}>
            <ContentTitle2>
              <h1>Oauth 권한 관리</h1>
            </ContentTitle2>
            <FormCard>
              <OauthList
                oauthListItems={data?.data.items||[]}
                authError={authError}
                handleUpdateGrade={handleUpdateGrade}
              />
            </FormCard>
            <PopupWrap className={isOpenMessage? 'fade-in' : 'fade-out'}>
              <div>{Message}</div>
            </PopupWrap>
          </div>
          </>
        )}
      />
    </Fragment>
  );
}

export default OauthListContainer;