import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { listDoorlockAll } from '../../api/doorlock';
import LimitButton from '../../components/Commons/LimitSelectBox';
import Pagination from '../../components/Commons/TablePagination';
import DoorlockAllList from '../../components/Doorlock/DoorlockAllList';
import MainLayout from '../../components/Layout/MainLayout';
import useDebounce from '../../lib/useDebounce';
import { RootState } from '../../stores';
import { listBuildingAction, initialize as initializeBuilding } from '../../stores/buildingList';
import { changeField, initialize, setCurrentPageNumberAction, setFilterItemAction, setFilterItemHotelAction, setInitFilterItemAction, setPaginationItemAction, setSortItemAction } from '../../stores/doorlockAllList';
import { listFloorAction, initialize as initializeFloor } from '../../stores/floorList';
import { listHotelAction } from '../../stores/hotelList';
import { doorlockListState } from '../../types/doorlock';
import DoorlockUninstallContainer from './DoorlockUninstallContainer';

/* styled */
const FormCard = styled.article`
  width: 100%;
  position: relative;

  .total-number {
    top: 10px;
  }
`;
const ContentTitle2 = styled.div`
  display: flex;
  padding: 0.625rem 1.875rem;
  margin-left: -1.875rem;
  margin-bottom: 0.625rem;
  width: 100%;
  height: 2rem;
  font-weight: bold;
  position: relative;
  background: #ffffff;

    h1,
    h2 {
      cursor: default;
      margin: 0;
      line-height: 2rem;
    }
    
@media screen and (max-width: 768px) {
  margin-left: -1rem;
  margin-bottom: 0;
  padding: 0.625rem 1rem;
  width: 100%;

  ul {
    width: 100%;

    .tab-item {
      display: flex;
      width: 100%;
      min-width: 50px;
      line-height: 1.3;
      align-items: center;
      justify-content: center;
    }
  }
}
`;
const DoorlockAllListContainer = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { currentPageNumber, paginationItem, filterItem, sortItem, floorListItems, buildingListItems, header, hotelListItems } = useSelector(({ doorlockAllList, floorList, buildingList, header, hotelList }:RootState) => ({
    currentPageNumber: doorlockAllList.currentPageNumber,
    paginationItem: doorlockAllList.paginationItem,
    filterItem: doorlockAllList.filterItem,
    sortItem:  doorlockAllList.sortItem,
    floorListItems: floorList.floorListItems,
    buildingListItems: buildingList.buildingListItems,
    header: header,
    hotelListItems: hotelList.hotelListItems,
  }));
  const [ isOpenDoorlockUninstallModal, setIsOpenDoorlockUninstallModal ] = useState<boolean>(false);
  const [ selectedHotelListItems, setSelectedHotelListItems ] = useState<any[]>([]);
  const [ selectedDoorlockId, setSelectedDoorlockId ] = useState<string>('');
  const [ selectedHotelId, setSelectedHotelId ] = useState<string>('');
  const [ selectedDoorlockName, setSelectedDoorlockName ] = useState<string>('');
  const [doorlockName, setDoorlockName] = useState<string>(filterItem.name);
  const [serialName, setSerialName] = useState<string>(filterItem.serial);
  const [fwVersionName, setFwVersionName] = useState<string>(filterItem.fwVersion);

  const {data, status, error:doorlockError} = useQuery<any, any>(['doorlock_all_list', filterItem, sortItem, paginationItem], 
  () => listDoorlockAll({...filterItem, ...sortItem, ...paginationItem})
  ,{
    staleTime: 1 * 60 * 1000, //최신 데이터에 대한 값 1분 (1분이 지나기 전에 같은 행동 반복 시 이전 데이터가 가지고 와짐)
    cacheTime: 10 * 60 * 1000, //캐시 값 10분
    refetchInterval: 30 * 60 * 1000, //인터벌 30분 (30분 마다 새롭게 function 실행)
    refetchOnWindowFocus: false, //브라우저로 포커스 이동에 따른 리패치
    retry: 1, //호출 실패 시 재호출 횟수
  });

  //MainLayout - refresh용
  const changeDoorlock = useCallback(() => {
    dispatch(initialize()); //doorlockAllList 기본값 초기화
  }, [dispatch]);

  const handleListHotel = useCallback(() => {
    dispatch(listHotelAction({}));
  }, [dispatch]);

  const handleListBuilding = useCallback(() => {
    dispatch(listBuildingAction({hotelId: filterItem.hotelId}));
  }, [dispatch, filterItem]);

  const handleListFloor = useCallback(() => {
    dispatch(listFloorAction({...filterItem, hotelId: filterItem.hotelId}));
  }, [dispatch, filterItem]);
  
  //필터 핸들링
  const handleFilter =  useCallback((targetItem:any, isDebounce:boolean) => {
    if(Object.keys(targetItem)[0] === 'name') setDoorlockName(targetItem.name);
    if(Object.keys(targetItem)[0] === 'serial') setSerialName(targetItem.serial);
    if(Object.keys(targetItem)[0] === 'fwVersion') setFwVersionName(targetItem.fwVersion);
    
    !isDebounce? dispatch(setFilterItemAction({...filterItem, ...targetItem})) : handleDebounce(() => dispatch(setFilterItemAction({...filterItem, ...targetItem})));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filterItem]);

  //필터 핸들링
  const handleFilterHotel =  useCallback((targetItem:any, isDebounce:boolean) => {
    dispatch(setFilterItemHotelAction({value: targetItem.hotelId.value, label: targetItem.hotelId.label}));
  }, [dispatch]);

  //필터 초기화 버튼
  const handleinitFilter = useCallback(() => {
    dispatch(setInitFilterItemAction());
    dispatch(initializeBuilding());
    dispatch(initializeFloor());
    setDoorlockName('');
    setSerialName('');
    setFwVersionName('');
  },[dispatch]);

  //현재 페이지 전역상태 등록
  const handleCurrentPageNumber = useCallback((currentPageNumber:number) => {
      dispatch(setCurrentPageNumberAction({currentPageNumber}));
  }, [dispatch]);

  //페이지 전역상태 등록
  const handlePaginationItem = useCallback((paginationItem:doorlockListState['paginationItem']) => {
      dispatch(setPaginationItemAction({paginationItem}));
  }, [dispatch]);

  //페이지네이션 이동
  const changePagination = useCallback((pageNumber:number) => {
      handleCurrentPageNumber(pageNumber);
      handlePaginationItem({
        ...paginationItem,
        offset: (pageNumber - 1) * paginationItem.limit,
      });
  },[handleCurrentPageNumber, handlePaginationItem, paginationItem]);

  //목록 리미트
  const changeLimit = useCallback((limit:number) => {
      handleCurrentPageNumber(1);
      handlePaginationItem({
        offset: 0,
        limit,
      });
  },[handleCurrentPageNumber, handlePaginationItem]);

  const changeSort = useCallback((sort:string) => {
    if (sortItem.sort === sort) {
      dispatch(setSortItemAction({
        sortItem:{
          ...sortItem,
          order: sortItem.order === 'desc' ? 'asc':'desc',
        }
      }));
    } else {
      dispatch(setSortItemAction({
        sortItem:{
          sort,
          order: 'desc',
        }
      }));
    }
  },[dispatch, sortItem]);

  //상세화면 이동용
  const handleDoorlockDetail = useCallback((doorlockId:string, hotelId:string) => {
    navigation(`/doorlock/${doorlockId}`);
    dispatch(changeField({
      key: 'hotelId',
      value: hotelId,
    }));
  },[dispatch, navigation]);

  //모달 플래그
  const handleDoorlockUninstallModal = useCallback((doorlockId:string, status:string, doorlockName:string, hotelId:string) => {
    if (status !== 'installed') return;
    if (header.hotelRole === 'manager') return;
    setSelectedDoorlockId(doorlockId);
    setSelectedDoorlockName(doorlockName);
    setIsOpenDoorlockUninstallModal(true);
    setSelectedHotelId(hotelId);
  },[header.hotelRole]);

  //디바운싱 훅으로 이동
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebounce = useCallback(
    useDebounce((handleListDoorlock) => handleListDoorlock(), 300) //0.3초 동안 미입력 시 함수 실행
  ,[]);
  
  useEffect(() => {
    handleListHotel();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  
  useEffect(() => {
    if(hotelListItems){
      if(hotelListItems.length){
        setSelectedHotelListItems(
          hotelListItems.map((item:any) => {
            return {value: item.id, label: item.name}
          })
        );
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[hotelListItems]);
    
  useEffect(() => {
    if(filterItem.hotelId) handleListBuilding();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filterItem.hotelId]);
    
  useEffect(() => {
    if(filterItem.buildingId) handleListFloor();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filterItem.buildingId]);

  useEffect(() => {
    if(status === 'loading'){
      dispatch(changeField({
        key: 'listLoading',
        value: true,
      }));
    }else{
      dispatch(changeField({
        key: 'listLoading',
        value: false,
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[status]);

      
  useEffect(() => {
    if(doorlockError){
      if(doorlockError?.response){
        if(doorlockError.response.data?.code === 401 || doorlockError.response.data?.code === 419){
          localStorage.clear();
          navigation('/login');
          dispatch(changeField({
            key: 'listLoading',
            value: false,
          }));
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[doorlockError]);
  
  useEffect(() => {
    if(header.userRole !== 'master' || header.name !== 'Oauth'){
      navigation('/hotel');
      dispatch(changeField({
        key: 'listLoading',
        value: false,
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[doorlockError]);

  const handleRefacth = useCallback(() => {
    queryClient.invalidateQueries(['doorlock_all_list', filterItem, sortItem, paginationItem]);
  },[queryClient, filterItem, sortItem, paginationItem]);

  return (
    <Fragment>
      <MainLayout
        refresh={changeDoorlock}
        ContentBody={(
          <>
          <ContentTitle2>
            <h1>전체 도어락 목록</h1>
            {/* <FontAwesomeIcon icon={faFilter} className={`filter-link ${isFilter? 'filter-on' : ''}`} onClick={() => setIsFilter(!isFilter)}/> */}
          </ContentTitle2>
          <FormCard>
            <DoorlockAllList
              doorlockListItems={data?.data.items||[]}
              selectedHotelListItems={selectedHotelListItems}
              buildingListItems={buildingListItems}
              floorListItems={floorListItems}
              filterItem={filterItem}
              sortItem={sortItem}
              userRole={header.userRole}
              doorlockName={doorlockName}
              serialName={serialName}
              fwVersionName={fwVersionName}
              doorlockTotal={data?.data.total||0}
              handleFilter={handleFilter}
              handleFilterHotel={handleFilterHotel}
              handleinitFilter={handleinitFilter}
              handleDoorlockDetail={handleDoorlockDetail}
              handleDoorlockUninstallModal={handleDoorlockUninstallModal}
              changeSort={changeSort}
              handleRefacth={handleRefacth}
            />
            <LimitButton
              currentLimit={paginationItem.limit}
              changeLimit={changeLimit}
            />
            <Pagination
              total={data?.data.total||0}
              index={currentPageNumber}
              limit={paginationItem.limit}
              indexChange={changePagination}
            />
          </FormCard>
          <DoorlockUninstallContainer
            isOpen={isOpenDoorlockUninstallModal}
            toggle={() => setIsOpenDoorlockUninstallModal(!isOpenDoorlockUninstallModal)}
            reload={() => handleRefacth()}
            doorlockId={selectedDoorlockId}
            doorlockName={selectedDoorlockName}
            hotelId={selectedHotelId}
          />
        </>
        )}
      />
    </Fragment>
  );
}

export default DoorlockAllListContainer;