import React, { useState, useEffect, Fragment, useCallback } from 'react';
import Pagination from '../../components/Commons/TablePagination';
import UserkeyIssueContainer from './UserkeyIssueContainer';
import { SMSSendContainer } from '../Commons/SMSSendContainer';
import UserkeyList from '../../components/Userkey/UserkeyList';
import UserkeyDeleteContainer from './UserkeyDeleteContainer';
import { useDispatch, useSelector} from 'react-redux';
import { changeAllField } from '../../stores/sms';
import { UserkeyListContainerProps } from '../../types/userkey';
import { RootState } from '../../stores';
import { selectRoomIdAction } from '../../stores/room';
//import QrCodePreview from '../../components/QrCode/QrCodePreview';
//import html2canvas from 'html2canvas';

const UserkeyListContainer = ({
  buildingId,
  bookingId,
  roomId,
  roomName,
  userkeyListItems,
  allowInfinityPincode,
  userRole,
  reload,
  handleGoBack,
}:UserkeyListContainerProps) => {
  const dispatch = useDispatch();
  const { roomDoorlockItems } = useSelector(({ room }:RootState) => ({
    roomDoorlockItems: room.room.doorlock,
  }));
  const [ isOpenKeyIssueModal, setIsOpenKeyIssueModal ] = useState<boolean>(false);
  const [ isOpenKeyDeleteModal, setIsOpenKeyDeleteModal ] = useState<boolean>(false);
  const [ isOpenSMSSendModal, setIsOpenSMSSendModal ] = useState<boolean>(false);
  const [ isOpenQrCodeModal, setIsOpenQrCodeModal ] = useState<boolean>(false);
  const [ paginationItem, setPaginationItem ] = useState<any>({
    offset: 0,
    limit: 10,
  });
  const [ selectedKeyId, setSelectedKeyId ] = useState<string|null>(null);
  const [ currentPageNumber, setCurrentPageNumber ] = useState<number>(1);

  const handleSelectRoom = useCallback(() => {
    if(buildingId) dispatch(selectRoomIdAction({buildingId, roomId}));
  },[buildingId, dispatch, roomId]);

  const changePagination = (pageNumber:number) => {
    setCurrentPageNumber(pageNumber);
    setPaginationItem({
      ...paginationItem,
      offset: (pageNumber - 1) * paginationItem.limit,
    });
  };

  const handleSelectMobilekeyDelete = (keyId:string, pincode:string) => {
    if (pincode) return;
    setSelectedKeyId(keyId);
    setIsOpenKeyDeleteModal(true);
  };

  const handleSelectSMSSend = (pincodeId:string, pincode:string, userId:string, exchangekey:string, qrcodeId:string) => {
    if (!pincode && !exchangekey) return;
    if (pincode) {
      dispatch(changeAllField({
        sms: {
          type: qrcodeId? 'qrcode' : 'pincode',
          value: pincode,
          keyId: pincodeId,
        }
      }));
    } else {
      dispatch(changeAllField({
        sms: {
          type: 'mobilekey',
          value: exchangekey,
          keyId: userId,
        }
      }));
    }
    setIsOpenSMSSendModal(true);
  };

  const handleKeyIssue = () => {
      setIsOpenKeyIssueModal(true);
  }

  const handleReload = () => {
    reload();
    setIsOpenSMSSendModal(!isOpenSMSSendModal);
  }
  const handleQrCodePreview = () => {
    setIsOpenQrCodeModal(true);
  }
  /* const handleQrDownload = () => {
    const capture:any = document.querySelector('#QrcodeWrap');
    if(capture){
      html2canvas(capture).then(
        (canvas) => {
          saveAs(canvas.toDataURL('image/jpg'), '이미지.jpg');
        }
      );
    }
  } */
  /* const saveAs = (uri:string, filename:string) => {
    let link = document.createElement('a');
    if(typeof link.download === 'string'){
      link.href = uri;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } */

  useEffect(() => {
    if (buildingId) handleSelectRoom();
  }, [handleSelectRoom, buildingId]);

  return (
    <Fragment>
      <div>
        <UserkeyList 
          userkeyListItems={userkeyListItems}
          pincodeVersion={roomDoorlockItems? roomDoorlockItems.pincodeVersion : ''}
          userRole={userRole}
          handleGoBack={handleGoBack}
          handleKeyIssue={handleKeyIssue}
          handleSelectMobilekeyDelete={handleSelectMobilekeyDelete}
          handleSelectSMSSend={handleSelectSMSSend}
          handleQrCodePreview={handleQrCodePreview}
        />
        <Pagination
          total={userkeyListItems.length}
          index={currentPageNumber}
          limit={paginationItem.limit}
          indexChange={changePagination}
        />
      </div>
      {isOpenKeyIssueModal &&
        <UserkeyIssueContainer
          isOpen={isOpenKeyIssueModal}
          toggle={() => setIsOpenKeyIssueModal(!isOpenKeyIssueModal)}
          reload={reload}
          buildingId={buildingId}
          bookingId={bookingId}
          roomId={roomId}
          roomName={roomName}
          allowInfinityPincode={allowInfinityPincode}
        />
      }
      <UserkeyDeleteContainer
        isOpen={isOpenKeyDeleteModal}
        toggle={() => setIsOpenKeyDeleteModal(!isOpenKeyDeleteModal)}
        reload={reload}
        bookingId={bookingId}
        keyId={selectedKeyId? selectedKeyId : ''}
      />
      {!isOpenKeyIssueModal &&
        <SMSSendContainer
          isOpen={isOpenSMSSendModal}
          isResend={true}
          toggle={() => setIsOpenSMSSendModal(!isOpenSMSSendModal)}
          bookingId={bookingId}
          reload={handleReload}
        />
      }
      {/* {isOpenQrCodeModal &&
        <QrCodePreview
          isOpen={isOpenQrCodeModal}
          toggle={() => setIsOpenQrCodeModal(!isOpenQrCodeModal)}
          qrItem={'7A4A7DC89EF1B75ED50FF65285C6967C5A30E8EA0118392D30ECF85D7A17F63A282CA1C7E5DC914A6ACD00E2187E075F0E21FCDBABCCF8B2F2BBB6AA05E4582E84CA96B19869560C071F308E2ADEB8B76B277BF09416C2395313D5CE6261C5AD'}
          handleQrDownload={handleQrDownload}
        />
      } */}
    </Fragment>
  );
};

export default UserkeyListContainer;