import { faBars, faBuilding, faCalendarAlt, faChartLine, faCommentSms, faHotel, faPersonShelter, faPollH, faRectangleList, faStairs, faTableList, faUnlockAlt, faUserAlt, faUsersGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { LeftMenuProps } from "../../types/commons";

/* styled */
const LeftMenuWrap = styled.aside`
  position: relative;
  float: left;
  width: 239px;
  background: #ffffff;
  overflow: hidden;
  transition-duration: 0.5s;

  ul:last-child {
    transform: translateX(0px);
    transition-duration: 0.5s;
  }
  &.hide {
    width: 48px;

    ul:last-child {
      transform: translateX(-180px);
      transition-duration: 0.5s;
    }
  }
  &.hide-over {
    width: 48px;
    overflow: visible;

    ul:last-child {
      position: absolute;
      top: 52px;
      padding-top: 0;
      height: 100%;
      background: #ffffff;
      transform: translateX(0px);
      transition-duration: 0s;
      box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.20),
      0 2px 4px -2px rgba(0, 0, 0, 0.30);
      z-index: 1;
    }
  }
  .background {
    display: none;
  }
  
  @media screen and (max-height: 530px) {
    min-height: 530px;
  }
  
  @media screen and (max-width: 1350px) {
    width: 48px;
    overflow: visible;

    &.hide-over-m {
      ul:last-child {
        position: absolute;
        top: 52px;
        padding-top: 0;
        height: 100%;
        background: #ffffff;
        transform: translateX(0px);
        transition-duration: 0s;
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.20),
        0 2px 4px -2px rgba(0, 0, 0, 0.30);
        z-index: 1;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 0;
    transition-duration: 0s;
    overflow: hidden;
    ul {
      overflow: hidden;
    }
    ul:last-child {
      transition-duration: 0s;
    }
    &.hide,
    &.hide-over-m,
    &.hide-over {
      position: fixed;
      width: 100%;
      height: 100%;
      background: none;
      z-index: 11;

      ul:last-child {
        display: block;
        position: absolute;
        top: 0.5px;
        margin-left: 0;
        padding-top: 0;
        height: 100%;
        background: #ffffff;
        transform: translateX(0px);
        transition-duration: 0s;
        box-shadow: 0 0 0;
      }
    }
    .background {
      display: block;
      position: fixed;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0);
      opacity: 0.1;
    }
  }
`;
const LeftMenuContent = styled.ul`
  position: relative;
  width: 239px;
  margin: auto;
  padding: 0;
  padding-top: 52px;
  list-style: none;

  li {
    margin-left: 57px;
    margin-right: 10px;
    padding: 15px 0;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    cursor: pointer;

    span {
      margin-left: 15px;
    }

    div {
      padding: 1.125rem 1rem;
      :hover {
        background: #ffffff;
      }
    }
    :hover {
      border-radius: 30px;
      background: #f0f2f5;
    }
    &.item-on {
      border-radius: 30px;
      background: #c2dbff;
    }
  }
`;
const MenuSitebar = styled.ul`
  position: relative;
  float: left;
  margin: auto;
  padding: 0;
  height: 100%;
  list-style: none;
  border-right: 1px solid #cccccc;
  background: #f2f6fc;
  z-index: 2;

  li {
    display: flex;
    padding: 0 5px;
    height: 48px;
    justify-content: center;
    align-items: center;

    svg {
      padding: 10px 0;
      width: 38px;
      cursor: pointer;
    }
    svg:hover {
      border-radius: 20px;
      background: #e6e8ec;
    }
    &.item-on {
      svg {
        border-radius: 20px;
        background: #c2dbff;
      }
    }

    :first-child {
      height: 52px;
      
      &.item-on {
        svg {
          border-radius: 20px;
          background: #e6e8ec;
        }
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    border-top: 1px solid #cccccc;
    
    li:first-child {
      display: none;
    } 
  }
`;

const LeftMenu = ({
  menuItem,
  homeMenuItem,
  hotelRole,
  userName,
  userRole,
  menuShow,
  mouseOver,
  handleMenuItem,
  handleHomeMenuItem,
  handleMenuShow,
  handleMenuBack,
  setMouseOver,
}:LeftMenuProps) => {

return menuItem === ''? (
  <LeftMenuWrap className={`${!menuShow? mouseOver? 'hide-over' : 'hide': mouseOver? 'hide-over-m' : ''}`}>
    <div className="background" onClick={() => handleMenuBack(!menuShow)}></div>
    <MenuSitebar onMouseLeave={() => setMouseOver(false)}>
      <li>
        <FontAwesomeIcon icon={faBars} title='메뉴 노출' onClick={() => handleMenuShow(!menuShow)}/>
      </li>
      {/* <li className={`${homeMenuItem === 'dashboard' ? 'item-on':''}`}>
        <FontAwesomeIcon icon={faChartLine} title='대시보드' onClick={() => handleHomeMenuItem('dashboard', false)} onMouseOver={() => setMouseOver(true)}/>
      </li> */}
      <li className={`${homeMenuItem === '' ? 'item-on':''}`}>
        <FontAwesomeIcon icon={faHotel} title='단지 관리' onClick={() => handleHomeMenuItem('', false)} onMouseOver={() => setMouseOver(true)}/>
      </li>
      {userName === 'Oauth' && userRole === 'master' && <>
        <li className={`${homeMenuItem === 'template' ? 'item-on':''}`}>
          <FontAwesomeIcon icon={faRectangleList} title='알림 템플릿 목록' onClick={() => handleHomeMenuItem('template', false)} onMouseOver={() => setMouseOver(true)}/>
        </li>
        <li className={`${homeMenuItem === 'hotelnotify' ? 'item-on':''}`}>
          <FontAwesomeIcon icon={faTableList} title='단지 알림 템플릿 목록' onClick={() => handleHomeMenuItem('hotelnotify', false)} onMouseOver={() => setMouseOver(true)}/>
        </li>
        <li className={`${homeMenuItem === 'channel' ? 'item-on':''}`}>
          <FontAwesomeIcon icon={faCommentSms} title='알림 채널' onClick={() => handleHomeMenuItem('channel', false)} onMouseOver={() => setMouseOver(true)}/>
        </li>
        <li className={`${homeMenuItem === 'doorlock' ? 'item-on':''}`}>
          <FontAwesomeIcon icon={faUnlockAlt} title='전체 도어락 목록' onClick={() => handleHomeMenuItem('doorlock', false)} onMouseOver={() => setMouseOver(true)}/>
        </li>
        <li className={`${homeMenuItem === 'oauth' ? 'item-on':''}`}>
            <FontAwesomeIcon icon={faUsersGear} title='Oauth 권한 관리' onClick={() => handleHomeMenuItem('oauth', false)} onMouseOver={() => setMouseOver(true)}/>
        </li>
      </>}
    </MenuSitebar>
    <LeftMenuContent onMouseOver={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)}>
      {/* <li className={`menu-item ${homeMenuItem === 'dashboard' ? 'item-on':''}`}
        onClick={() => handleHomeMenuItem('dashboard', false)}><span>대시보드</span></li> */}
      <li className={`menu-item ${homeMenuItem === '' ? 'item-on':''}`}
        onClick={() => handleHomeMenuItem('', false)}><span>단지 관리</span></li>
      {userName === 'Oauth' && userRole === 'master' && 
      <>
        <li className={`menu-item ${homeMenuItem === 'template'? 'item-on':''}`}
          onClick={() => handleHomeMenuItem('template', false)}><span>알림 템플릿 목록</span></li>
        <li className={`menu-item ${homeMenuItem === 'hotelnotify' ? 'item-on':''}`}
          onClick={() => handleHomeMenuItem('hotelnotify', false)}><span>단지 알림 템플릿 목록</span></li>
        <li className={`menu-item ${homeMenuItem === 'channel' ? 'item-on':''}`}
          onClick={() => handleHomeMenuItem('channel', false)}><span>알림 채널</span></li>
        <li className={`menu-item ${homeMenuItem === 'doorlock' ? 'item-on':''}`}
          onClick={() => handleHomeMenuItem('doorlock', false)}><span>전체 도어락 목록</span></li>
        <li className={`menu-item ${homeMenuItem === 'oauth' ? 'item-on':''}`}
          onClick={() => handleHomeMenuItem('oauth', mouseOver)}><span>Oauth 권한 관리</span></li>
      </>
      }
    </LeftMenuContent>
  </LeftMenuWrap>
  ):(
  <LeftMenuWrap className={`${!menuShow? mouseOver? 'hide-over' : 'hide': mouseOver? 'hide-over-m' : ''}`}>
    <div className="background" onClick={() => handleMenuBack(!menuShow)}></div>
    {hotelRole === 'master' && 
    <>
      <MenuSitebar onMouseLeave={() => setMouseOver(false)}>
        <li>
          <FontAwesomeIcon icon={faBars} title='메뉴 노출' onClick={() => handleMenuShow(!menuShow)}/>
        </li>
        {/* <li className={`${menuItem === 'dashboard' ? 'item-on':''}`}>
          <FontAwesomeIcon icon={faChartLine} title='대시보드' onClick={() => handleMenuItem('dashboard', false)} onMouseOver={() => setMouseOver(true)}/>
        </li> */}
        <li className={`${menuItem === 'hotel' ? 'item-on':''}`}>
          <FontAwesomeIcon icon={faHotel} title='단지 정보' onClick={() => handleMenuItem('hotel', false)} onMouseOver={() => setMouseOver(true)}/>
        </li>
        <li className={`${menuItem === 'building' ? 'item-on':''}`}>
          <FontAwesomeIcon icon={faBuilding} title='빌딩 목록' onClick={() => handleMenuItem('building', false)} onMouseOver={() => setMouseOver(true)}/>
        </li>
        <li className={`${menuItem === 'floor' ? 'item-on':''}`}>
          <FontAwesomeIcon icon={faStairs} title='층 목록' onClick={() => handleMenuItem('floor', false)} onMouseOver={() => setMouseOver(true)}/>
        </li>
        <li className={`${menuItem === 'room' ? 'item-on':''}`}>
          <FontAwesomeIcon icon={faPersonShelter} title='객실 목록' onClick={() => handleMenuItem('room', false)} onMouseOver={() => setMouseOver(true)}/>
        </li>
        <li className={`${menuItem === 'doorlock' ? 'item-on':''}`}>
          <FontAwesomeIcon icon={faUnlockAlt} title='도어락 목록' onClick={() => handleMenuItem('doorlock', false)} onMouseOver={() => setMouseOver(true)}/>
        </li>
        <li className={`${menuItem === 'booking' ? 'item-on':''}`}>
          <FontAwesomeIcon icon={faCalendarAlt} title='예약 목록' onClick={() => handleMenuItem('booking', false)} onMouseOver={() => setMouseOver(true)}/>
        </li>
        <li className={`${menuItem === 'staff' ? 'item-on':''}`}>
          <FontAwesomeIcon icon={faUserAlt} title='스태프 목록' onClick={() => handleMenuItem('staff', false)} onMouseOver={() => setMouseOver(true)}/>
        </li>
        <li className={`${menuItem === 'hotelnotify' ? 'item-on':''}`}>
          <FontAwesomeIcon icon={faTableList} title='알림 템플릿' onClick={() => handleMenuItem('hotelnotify', false)} onMouseOver={() => setMouseOver(true)}/>
        </li>
        <li className={`${menuItem === 'report' ? 'item-on':''}`}>
          <FontAwesomeIcon icon={faPollH} title='리포트' onClick={() => handleMenuItem('report', false)} onMouseOver={() => setMouseOver(true)}/>
        </li>
      </MenuSitebar>
       <LeftMenuContent onMouseOver={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)}>
          {/* <li className={`menu-item ${menuItem === 'dashboard' ? 'item-on':''}`}
              onClick={() => handleMenuItem('dashboard', false)}><span>대시보드</span>
          </li> */}
          <li className={`menu-item ${menuItem === 'hotel' ? 'item-on':''}`}
              onClick={() => handleMenuItem('hotel', false)}><span>단지 정보</span>
          </li>
          <li className={`menu-item ${menuItem === 'building' ? 'item-on':''}`}
              onClick={() => handleMenuItem('building', false)}><span>빌딩 목록</span>
          </li>
          <li className={`menu-item ${menuItem === 'floor' ? 'item-on':''}`}
              onClick={() => handleMenuItem('floor', false)}><span>층 목록</span>
          </li>
          <li className={`menu-item ${menuItem === 'room' ? 'item-on':''}`}
              onClick={() => handleMenuItem('room', false)}><span>객실 목록</span>
          </li>
          <li className={`menu-item ${menuItem === 'doorlock' ? 'item-on':''}`}
              onClick={() => handleMenuItem('doorlock', false)}><span>도어락 목록</span>
          </li>
          <li className={`menu-item ${menuItem === 'booking' ? 'item-on':''}`}
              onClick={() => handleMenuItem('booking', false)}><span>예약 목록</span>
          </li>
          <li className={`menu-item ${menuItem === 'staff' ? 'item-on':''}`}
              onClick={() => handleMenuItem('staff', false)}><span>스태프 목록</span>
          </li>
          <li className={`menu-item ${menuItem === 'hotelnotify' ? 'item-on':''}`}
              onClick={() => handleMenuItem('hotelnotify', false)}><span>알림 템플릿</span>
          </li>
          <li className={`menu-item ${menuItem === 'report' ? 'item-on':''}`}
              onClick={() => handleMenuItem('report', false)}><span>리포트</span>
          </li> 
        </LeftMenuContent>
      </>}
      {hotelRole !== 'master' &&     <>
        <MenuSitebar onMouseLeave={() => setMouseOver(false)}>
          <li>
            <FontAwesomeIcon icon={faBars} title='메뉴 노출' onClick={() => handleMenuShow(!menuShow)}/>
          </li>
          {/* <li className={`${menuItem === 'dashboard' ? 'item-on':''}`}>
            <FontAwesomeIcon icon={faChartLine} title='대시보드' onClick={() => handleMenuItem('dashboard', false)} onMouseOver={() => setMouseOver(true)}/>
          </li> */}
          <li className={`${menuItem === 'doorlock' ? 'item-on':''}`}>
            <FontAwesomeIcon icon={faUnlockAlt} title='도어락 목록' onClick={() => handleMenuItem('doorlock', false)} onMouseOver={() => setMouseOver(true)}/>
          </li>
          <li className={`${menuItem === 'booking' ? 'item-on':''}`}>
            <FontAwesomeIcon icon={faCalendarAlt} title='예약 목록' onClick={() => handleMenuItem('booking', false)} onMouseOver={() => setMouseOver(true)}/>
          </li>
          <li className={`${menuItem === 'staff' ? 'item-on':''}`}>
            <FontAwesomeIcon icon={faUserAlt} title='스태프 목록' onClick={() => handleMenuItem('staff', false)} onMouseOver={() => setMouseOver(true)}/>
          </li>
          <li className={`${menuItem === 'report' ? 'item-on':''}`}>
            <FontAwesomeIcon icon={faPollH} title='리포트' onClick={() => handleMenuItem('report', false)} onMouseOver={() => setMouseOver(true)}/>
          </li>
        </MenuSitebar>
       <LeftMenuContent onMouseOver={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)}>
          {/* <li className={`menu-item ${menuItem === 'dashboard' ? 'item-on':''}`}
              onClick={() => handleMenuItem('dashboard', false)}><span>대시보드</span>
          </li> */}
          <li className={`menu-item ${menuItem === 'doorlock' ? 'item-on':''}`}
              onClick={() => handleMenuItem('doorlock', false)}><span>도어락 목록</span>
          </li>
          <li className={`menu-item ${menuItem === 'booking' ? 'item-on':''}`}
              onClick={() => handleMenuItem('booking', false)}><span>예약 목록</span>
          </li>
          <li className={`menu-item ${menuItem === 'staff' ? 'item-on':''}`}
              onClick={() => handleMenuItem('staff', false)}><span>스태프 목록</span>
          </li>
          <li className={`menu-item ${menuItem === 'report' ? 'item-on':''}`}
              onClick={() => handleMenuItem('report', false)}><span>리포트</span>
          </li> 
       </LeftMenuContent>
      </>}
    </LeftMenuWrap>
  )
}

export default LeftMenu;