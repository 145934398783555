import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/Commons/LeftMenu";
import { RootState } from "../../stores";
import { setHomeMenuItemAction, setMenuItemAction } from "../../stores/breadcrumb";
import { setHeaderItemAction } from "../../stores/header";


const LeftMenuContainer = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { menuItem, homeMenuItem, userName, userRole, hotelRole, menuState } = useSelector(({ breadcrumb, header }:RootState) => ({
    menuItem: breadcrumb.menuItem,
    homeMenuItem: breadcrumb.homeMenuItem,
    userName: header.name,
    userRole: header.userRole,
    hotelRole: header.hotelRole,
    menuState: header.menuState,
  }));
  const [mouseOver, setMouseOver] = useState<boolean>(menuState.mouseOver);

  const handleMenuItem = useCallback((menuItem:string, flag:boolean) => {
    setMouseOver(flag);
    dispatch(setHeaderItemAction({
      key: 'mouseOver',
      value: flag,
    }));
    dispatch(setMenuItemAction({menuItem}));
  },[dispatch]);

  const handleMenuShow = useCallback((flag:boolean) => {
    setMouseOver(flag);
    dispatch(setHeaderItemAction({
      key: 'display',
      value: flag,
    }));
  },[dispatch]);
  
  const handleMenuBack = useCallback((flag:boolean) => {
    document.body.classList.toggle('modal-open', !flag);
    dispatch(setHeaderItemAction({
      key: 'display',
      value: flag,
    }));
  },[dispatch]);

  const handleHomeMenuItem = useCallback((homeMenuItem:string, flag:boolean) => {
    dispatch(setHomeMenuItemAction({homeMenuItem}));
    dispatch(setHeaderItemAction({
      key: 'mouseOver',
      value: flag,
    }));
    if(homeMenuItem === ''){
      return navigation(`/hotel`);
    }
    if(homeMenuItem === 'template' || homeMenuItem === 'hotelnotify'){
      return navigation('/notify_template');
    }
    if(homeMenuItem === 'channel'){
      return navigation('/notify_channel');
    }
    if(homeMenuItem === 'dashboard'){
      return navigation('/dashboard');
    }
    if(homeMenuItem === 'doorlock'){
      return navigation('/doorlock');
    }
    if(homeMenuItem === 'oauth'){
      return navigation('/oauth');
    }
  },[dispatch, navigation]);

  return <LeftMenu 
            menuItem={menuItem} 
            homeMenuItem={homeMenuItem}
            hotelRole={hotelRole}
            userName={userName}
            userRole={userRole}
            menuShow={menuState.display}
            mouseOver={mouseOver}
            handleMenuItem={handleMenuItem} 
            handleHomeMenuItem={handleHomeMenuItem}
            handleMenuShow={handleMenuShow}
            handleMenuBack={handleMenuBack}
            setMouseOver={setMouseOver}
         />
}

export default LeftMenuContainer;