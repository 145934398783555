import { createAction, handleActions } from "redux-actions";
import { CommonProps, currentPageNumberProps, filterItemProps, GetPayload, PaginationItemProps, sortItemProps } from "../types/commons";
import { doorlockAllListState } from "../types/doorlock";
import produce from "immer";

const INITIALIZE = 'doorlockAllList/INITIALIZE';
const CHANGE_FINELD = 'doorlockAllList/CHANGE_FINELD'; //데이터 변경용

const SET_DOORLOCK_LIST_CURRENT_PAGE_NUMBER = 'doorlockAllList/SET_DOORLOCK_LIST_CURRENT_PAGE_NUMBER' as const;
const SET_DOORLOCK_PAGINATION_ITEM = 'doorlockAllList/SET_DOORLOCK_PAGINATION_ITEM' as const;
const SET_DOORLOCK_FILTER_ITEM = 'doorlockAllList/SET_DOORLOCK_FILTER_ITEM' as const;
const SET_DOORLOCK_INIT_FILTER_ITEM = 'doorlockAllList/SET_DOORLOCK_INIT_FILTER_ITEM' as const;
const SET_DOORLOCK_HOTEL_FILTER_ITEM = 'doorlockAllList/SET_DOORLOCK_HOTEL_FILTER_ITEM' as const;
const SET_DOORLOCK_SORT_ITEM = 'doorlockAllList/SET_DOORLOCK_SORT_ITEM' as const;
const SET_DOORLOCK_DETAIL = 'doorlockAllList/SET_DOORLOCK_DETAIL' as const;

export const changeField = createAction(
  CHANGE_FINELD,
  ({ key, value }:CommonProps) => ({
    key, // hotel 내부 > name, timezone, address 등
    value, // 실제 바꾸려는 값
  }),
);
/* action */
export const initialize = createAction(INITIALIZE);

export const setCurrentPageNumberAction = createAction(SET_DOORLOCK_LIST_CURRENT_PAGE_NUMBER, ({currentPageNumber}:currentPageNumberProps) => ({
  currentPageNumber,
}));
export const setPaginationItemAction = createAction(SET_DOORLOCK_PAGINATION_ITEM, ({paginationItem}:PaginationItemProps) => (
  paginationItem
));
export const setFilterItemAction = createAction(SET_DOORLOCK_FILTER_ITEM, (filterItem:filterItemProps) => ({
  filterItem
}));

export const setFilterItemHotelAction = createAction(SET_DOORLOCK_HOTEL_FILTER_ITEM, ({value, label}:{value:string, label:string}) => ({
  value, 
  label,
}));
export const setInitFilterItemAction = createAction(SET_DOORLOCK_INIT_FILTER_ITEM);
export const setSortItemAction = createAction(SET_DOORLOCK_SORT_ITEM, ({sortItem}:sortItemProps) => ({
  sortItem
}));
export const setDetailField = createAction(SET_DOORLOCK_DETAIL, (detailtype:string) => ({
  detailtype
}));

const initialState:doorlockAllListState = {
  currentPageNumber: 1,
  paginationItem: {
    offset: 0,
    limit: 50,
  },
  sortItem: {
    sort: '',
    order: '',
  },
  filterItem: {
    hotelId: '',
    selectHotelId: {
      value: undefined,
      label: undefined,
    },
    buildingId: '',
    floorId: '',
    name: '',
    serial: '',
    type: '',
    status: '',
    fwType: '',
    fwVersion: '',
  },
  doorlockAllListError: null,
  detailField: {
    detailtype: 'detail',
  },
  listLoading: false,
  hotelId: '',
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof changeField | typeof setCurrentPageNumberAction | typeof setPaginationItemAction 
| typeof setFilterItemAction | typeof setFilterItemHotelAction | typeof setInitFilterItemAction | typeof setSortItemAction | typeof setDetailField>;

const doorlockAllList = handleActions<doorlockAllListState, Payloads>(
  {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    [CHANGE_FINELD] : (state, { payload: {key, value} }) => 
    produce(state, draft => {
      if(key === 'listLoading') draft.listLoading = value;
      if(key === 'hotelId') draft.hotelId = value;
    }),
    // 도어락 현재 페이지
    [SET_DOORLOCK_LIST_CURRENT_PAGE_NUMBER] : (state, { payload: {currentPageNumber} }) => ({
      ...state,
      currentPageNumber: currentPageNumber,
    }),
    // 도어락 페이지네이션
    [SET_DOORLOCK_PAGINATION_ITEM] : (state, { payload: paginationItem }) => ({
      ...state,
      paginationItem: paginationItem,
    }),
    // 도어락 필터
    [SET_DOORLOCK_FILTER_ITEM] : (state, { payload: filter }) => ({
      ...state,
      filterItem: filter.filterItem,
    }),
    // 필터 초기화
    [SET_DOORLOCK_INIT_FILTER_ITEM] : (state) => ({
      ...state,
      filterItem: {
        hotelId: '',
        selectHotelId: {
          value: undefined,
          label: undefined,
        },
        buildingId: '',
        floorId: '',
        name: '',
        serial: '',
        type: '',
        status: '',
        fwType: '',
        fwVersion: '',
      }
    }),
    // 필터 호텔 ID만
    [SET_DOORLOCK_HOTEL_FILTER_ITEM] : (state, { payload: {value, label} }) => ({
      ...state,
      filterItem: {
        hotelId: value,
        selectHotelId:{
          value,
          label,
        },
        buildingId: '',
        floorId: '',
        name: state.filterItem.name,
        serial: state.filterItem.serial,
        type: state.filterItem.type,
        status: state.filterItem.status,
        fwType: state.filterItem.fwType,
        fwVersion: state.filterItem.fwVersion,
      }
    }),
    // 도어락 정렬
    [SET_DOORLOCK_SORT_ITEM] : (state, { payload: sortItem }) => ({
      ...state,
      sortItem: sortItem.sortItem,
    }),
    // detail 접근 시 필드 저장
    [SET_DOORLOCK_DETAIL] : (state, { payload: detailField }) => ({
      ...state,
      detailField: {
        detailtype: detailField.detailtype,
      }
    }),
  },
  initialState,
);

export default doorlockAllList;
