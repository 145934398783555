import produce from "immer";
import { createAction, handleActions } from "redux-actions";
import { CommonProps, filterItemProps, GetPayload } from "../types/commons";
import { reportListState } from "../types/report";

const INITIALIZE = 'reportList/INITIALIZE';
const CHANGE_FINELD = 'reportList/CHANGE_FINELD'; //데이터 변경용

const SET_REPORT_FILTER_ITEM = 'reportList/SET_REPORT_FILTER_ITEM' as const;

export const changeField = createAction(
  CHANGE_FINELD,
  ({ key, value }:CommonProps) => ({
    key, // hotel 내부 > name, timezone, address 등
    value, // 실제 바꾸려는 값
  }),
);
/* action */
export const initialize = createAction(INITIALIZE);
export const setFilterItemAction = createAction(SET_REPORT_FILTER_ITEM, (filterItem:filterItemProps) => ({
    filterItem
}));

const initialState:reportListState = {
  filterItem: {
    reportType: 'userkey',
    buildingName: '',
  },
  reportListError: null,
  listLoading: false,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof changeField | typeof setFilterItemAction>;

const reportList = handleActions<reportListState, Payloads>(
  {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    [CHANGE_FINELD] : (state, { payload: {key, value} }) => 
    produce(state, draft => {
      if(key === 'listLoading') draft.listLoading = value;
    }),
    // 리포트 필터
    [SET_REPORT_FILTER_ITEM] : (state, { payload: filter }) => ({
      ...state,
      filterItem: filter.filterItem,
    }),
  },
  initialState,
);

export default reportList;
