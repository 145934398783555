import moment from "moment";
import { useEffect, useState, useRef } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { selectQRcode } from "../../api/booking";
import Spin from "../../components/Commons/Spin";
import QrCodeView from "../../components/QrCode/QrCodeView";

const QrCodeViewContainer = () => {
  const param = useParams();
  const qrcodeId = param.id? param.id : '';
  const header_token = !localStorage.getItem('api_key')? '' : `Bearer@${localStorage.getItem('api_key')}`;
  const intervalId = useRef<any>();
  const queryClient = useQueryClient();

  const checkNumber = (moment(new Date()).minute() * 60) + moment(new Date()).second();
  const [time, setTime] = useState<number>(3600 - checkNumber);
  const {data, status, isLoading, error, isError} = useQuery<any, any>(['getQrcodeToken'], 
  () => selectQRcode({qrcodeId})
  ,{
    staleTime: 100 * 60 * 1000, //최신 데이터에 대한 값 1분 (1분이 지나기 전에 같은 행동 반복 시 이전 데이터가 가지고 와짐)
    refetchInterval: 30 * 60 * 1000, //인터벌 30분 (30분 마다 새롭게 function 실행)
    refetchOnWindowFocus: false, //브라우저로 포커스 이동에 따른 리패치
    retry: 1, //호출 실패 시 재호출 횟수
  });

  const startCount = () => {
    intervalId.current = setInterval(
      () => setTime((time) => time - 1),
      1000,);
  };

  useEffect(() => {
    if(status === 'success'){
      clearInterval(intervalId.current);
      startCount();
    }
  }, [status]);

  useEffect(() => {
    if(time <= 0){
      queryClient.invalidateQueries(['getQrcodeToken',]);
      setTime(3600);
    }
  }, [time, queryClient]);

  //Viewer
  if(isLoading){
    return <Spin isSpin={isLoading}/>
  };
  if (isError) {
    return <span>Error: {error.message}</span>;
  };

  return (<QrCodeView
            data={data?.data?.result}
            header_token={header_token}
            time={time}
         />)
};

export default QrCodeViewContainer;